




















































import { getStudent } from "@/api/customerService";
import handleError from "@/helpers/errors";
import { isValidBirthdate } from "@/helpers/timeUtils";
import Learnlink from "@learnlink/interfaces";
import MyProjects from "@/components/customer/my-projects/MyProjectsCustomer.vue";
import MyTeamsCustomer from "@/components/customer/MyTeamsCustomer.vue";
import UpdateStudentNotificationDialog from "@/components/customer/UpdateStudentNotificationDialog.vue";
import YouHaveNewDiploma from "@/components/student/YouHaveNewDiploma.vue";
import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { getActiveProjects } from "@/api/projectService";
import { getMyActiveTeams } from "@/api/teamService";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "CustomerDashboard",
  components: { UpdateStudentNotificationDialog, YouHaveNewDiploma, MyTeamsCustomer, MyProjects },
  setup() {
    const vm = reactive({
      loading: false,
      projects: [] as Learnlink.Project.FullViewApp[],
      studentsToUpdate: [] as Learnlink.Student.Student[],
      teams: [] as Learnlink.Team.View.FullMetadata[],
      updateStudentNotificationDialogVisible: false,
    });

    async function getTeams() {
      vm.teams = await getMyActiveTeams(userModule.state.userId);
    }

    async function getProjects() {
      vm.projects = await getActiveProjects("customer", userModule.state.userId);
    }

    provide("getMyTeams", getTeams);

    onMounted(async () => {
      vm.loading = true;
      await Promise.all([
        getTeams(),
        getProjects(),
        mount(),
      ]);

      vm.loading = false;
    });

    async function mount() {
      try {
        const students = await Promise.all(
          userModule.state.user.students.map(async (uid) => await getStudent(uid)),
        );
        vm.studentsToUpdate = students.filter((student) => {
          if (student.account) return !isValidBirthdate(student.account.birthdate);
          return true;
        });
        vm.updateStudentNotificationDialogVisible = vm.studentsToUpdate.length > 0;
      }
      catch (e) {
        handleError(e);
      }
    }

    provide("getStudent", mount);

    return {
      user: userModule.state.user,
      vm,
    };
  },
});
