


























































import TeamInformation from "@/components/shared/TeamInformation.vue";
import { getBirthYearString } from "@/helpers/timeUtils";
import { computed, defineComponent, inject, onMounted, PropType, provide, reactive } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import AddStudentToTeamDialog from "@/components/customer/my-students-customer/AddStudentToTeamDialog.vue";
import Learnlink from "@learnlink/interfaces";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import CalendarCourse from "../shared/CalendarCourse.vue";

export default defineComponent({
  name: "TeamCardCustomer",
  components: {
    TeamInformation,
    AddStudentToTeamDialog,
    TeamCardHeader,
    CalendarCourse
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      addStudentToTeamDialogVisible: false,
      newPrivateMessages: 0,
      uid: userModule.state.userId,
    });

    const setOverlayActive = inject<(state: boolean) => void>("setOverlayActive");

    const hasStudents = computed(() => props.team.verboseStudents.filter((students) => students.parentUID === vm.uid).length);

    const birthYearString = props.team.birthYears?.length ? getBirthYearString(props.team.birthYears) : "";

    const newTeamMessages = computed((): number => {
      if (userModule.state.user.role !== "seller") {
        const lastRead = userModule.state.user.lastReadTeamMessages?.filter(o => o.teamID === props.team.ID)[0]?.read;
        if (!lastRead) return props.team.information?.length || 0;
        return props.team.information?.filter(message => message.created > lastRead).length;
      }
      else {
        return 0;
      }
    });

    function openAddStudentDialog(): void {
      vm.addStudentToTeamDialogVisible = true;
    }

    onMounted(() => {
      if (!hasStudents.value && setOverlayActive) setOverlayActive(true);
    });

    provide("openAddStudentDialog", openAddStudentDialog);

    return {
      birthYearString,
      hasStudents,
      newTeamMessages,
      setOverlayActive,
      vm,
    };
  },
});
