



















































































import { addStudentToTeam } from "@/api/teamService";
import analytics from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, inject, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { getStudents } from "@/api/customerService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import EditStudentCard from "@/components/customer/my-students-customer/EditStudentCard.vue";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "AddStudentToTeamDialog",
  components: {
    EditStudentCard,
    ProfilePhoto,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const notify = useNotifier();
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const setOverlayActive = inject<(state: boolean) => void>("setOverlayActive");

    const vm = reactive({
      loading: false,
      students: [] as Learnlink.Student.View.App.Thumbnail[],
    });

    onMounted(async () => {
      vm.loading = true;
      const students = await getStudents(userModule.state.userId);
      vm.students = students.filter((student) => !props.team.students.includes(student.uid));
      vm.loading = false;
    });

    async function addToTeam(student: Learnlink.Student.View.App.Thumbnail) {
      vm.loading = true;
      try {
        await addStudentToTeam(props.team.ID, student.uid, userModule.state.userId, props.team.courseID);
        getMyTeams && await getMyTeams();
        notify({
          title: "Lagt til! 🥳",
          message: `${student.firstName} ble lagt til i teamet.`,
          type: "success",
        });
        analytics.track("addedParticipant");
        emit("success");
        _value.value = false;
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    function close(): void {
      if (setOverlayActive) setOverlayActive(false);
      _value.value = false;
    }

    return {
      _value,
      addToTeam,
      close,
      userModule,
      vm,
    };
  },
});
