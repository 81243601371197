



















import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import ClosedProjectCardCustomer from "./ClosedProjectCardCustomer.vue";
import OpenProjectCardCustomer from "./OpenProjectCardCustomer.vue";

export default defineComponent({
  components: { OpenProjectCardCustomer, ClosedProjectCardCustomer },
  name: "MyProjectsCustomer",
  props: {
    projects: {
      type: Array as PropType<Learnlink.Project.FullViewApp[]>,
      required: true,
    }
  },
});
