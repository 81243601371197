



























import { userModule } from "@/store/modules/user";
import { computed, defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import EditStudentCard from "@/components/customer/my-students-customer/EditStudentCard.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "UpdateStudentNotificationDialog",
  components: {
    EditStudentCard,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    students: {
      type: Array as PropType<Learnlink.Student.Student[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const activeStudent = computed(() => {
      return props.students[0];
    });

    return {
      activeStudent,
      uid: computed(() => userModule.state.userId),
      _value,
    };
  },
});
