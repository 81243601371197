







































import { computed, defineComponent, PropType, provide, reactive } from "@vue/composition-api";
import TeamCardCustomer from "@/components/customer/TeamCardCustomer.vue";
import Learnlink from "@learnlink/interfaces";
import SupportPhoneAlert from "../SupportPhoneAlert.vue";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "MyTeamsCustomer",
  components: { TeamCardCustomer, SupportPhoneAlert },
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.View.FullMetadata[]>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      overlayActive: false,
    });

    function setOverlayActive(state: boolean): void {
      vm.overlayActive = state;
    }

    const sortedTeams = computed(() => {
      return [...props.teams].sort((a, b) => {
        const hasStudent = a.verboseStudents.filter((students) => students.parentUID === userModule.state.userId).length;
        const hasStudent2 = b.verboseStudents.filter((students) => students.parentUID === userModule.state.userId).length;

        if (hasStudent && !hasStudent2) {
          return 1;
        }
        else if (!hasStudent && hasStudent2) {
          return -1;
        }
        else {
          return 0;
        }
      }).sort((a, b) => {
        return a.created > b.created ? -1 : 1;
      });
    });

    provide("setOverlayActive", setOverlayActive);

    return {
      setOverlayActive,
      vm,
      sortedTeams
    };
  },
});
